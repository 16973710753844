import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '../router'
import { AESencrypt } from '@/utils/JsAes'

const Axios = axios.create({
  baseURL: '', // 因为我本地做了反向代理
  timeout: 10000,
  responseType: 'json',
  withCredentials: true, // 是否允许带cookie这些,true为允许
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// POST传参序列化(添加请求拦截器)
Axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做某件事
    // 若是有做鉴权token , 就给头部带上token
    if (store.getters.token) {
      config.headers.Authorization = store.getters.token;
    }
    if (store.getters.aeskey && store.getters.sessionkey) {
      const aeskey = store.getters.aeskey;
      let sessionkey = store.getters.sessionkey;
      if (aeskey !== '' && sessionkey !== '') {
        const aes = new AESencrypt.AES(aeskey);
        sessionkey = aes.Encrypt(sessionkey);
        config.headers.common['sessionkey'] = sessionkey;
      }
    }
    if (config.method === 'get') {
      config.params = {
        _t: Date.parse(new Date().toString()) / 1000,
        ...config.params
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(
  (res) => {
    if (res.data.SessionKey) {
      store.dispatch('setSessionkey', res.data.SessionKey.toString());
    } else {
      store.dispatch('setSessionkey', '');
    }

    // 对响应数据做些事
    return res;
  },
  (error) => {
    // 下面是接口回调的satus ,因为我做了一些错误页面,所以都会指向对应的报错页面
    if (
      error.response.status === 401 ||
      error.response.status === 403 ||
      error.response.status === 408
    ) {
      router.push({
        path: '/Login'
      });
    } else if (error.response.status === 404) {
      router.push({
        path: '/error'
      });
    }
    // 返回 response 里的错误信息
    return Promise.reject(error.response);
  }
)

// 对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
  install: (vue: typeof Vue, options: any): void => {
    Object.defineProperty(Vue.prototype, '$http', { value: Axios });
  }
}
