import { Commit, Dispatch } from 'vuex';
import shop from '../../api/shop';
import * as types from '../mutation-types';
import {
  CartProduct,
  CartShape,
  CheckoutStatus,
  AddToCartPayload,
  CheckedPayload,
  RemarksPayload,
  QuantityPayload
} from '../index';

interface CheckoutSuccessPayload {
  models: string[];
}

interface modelPayload {
  model: string;
}

export interface State {
  added: CartShape[];
  checkoutStatus: CheckoutStatus;
}

// initial state
// shape: [{ id, quantity }]
const state: State = {
  added: [],
  checkoutStatus: null
};

// getters
const getters = {
  checkoutStatus: (state: State) => state.checkoutStatus,
  cartCount: (state: State) => state.added.length,
  checkedSum: (state: State) => {
    let sum = 0;
    for (let i = 0; i < state.added.length; i++) {
      if (state.added[i].checked) {
        sum++;
      }
    }
    return sum;
  }
};

// actions
const actions = {
  removeCartModel(
    context: { commit: Commit; dispatch: Dispatch },
    model: string
  ) {
    const modelpayload: modelPayload = {
      model: model
    };
    context.commit(types.REMOVE_TO_CART, modelpayload);
    context.dispatch('saveToStorage');
  },
  checkout(
    context: { commit: Commit; dispatch: Dispatch },
    products: CartProduct[]
  ) {
    context.commit(types.CHECKOUT_RESET);
    const models: string[] = [];
    const orderlist: CartProduct[] = [];
    products.forEach((p) => {
      if (p.checked) {
        models.push(p.model);
        orderlist.push(p);
      }
    });
    const successPayload: CheckoutSuccessPayload = {
      models: [...models]
    };
    shop.buyProducts(
      orderlist,
      () => context.dispatch('updateCart', successPayload),
      () => context.commit(types.CHECKOUT_FAILURE)
    );
  },
  updateCart(
    context: { commit: Commit; dispatch: Dispatch },
    successPayload: CheckoutSuccessPayload
  ) {
    context.commit(types.CHECKOUT_SUCCESS, successPayload);
    context.dispatch('saveToStorage');
  },
  changeCartChecked(
    context: { commit: Commit; dispatch: Dispatch },
    checkedpayload: CheckedPayload
  ) {
    const chkpayload: CheckedPayload = {
      model: checkedpayload.model,
      checked: checkedpayload.checked
    };
    context.commit(types.CHANGE_CART_CHECKED, chkpayload);
    context.dispatch('saveToStorage');
  },
  changeCartRemarks(
    context: { commit: Commit; dispatch: Dispatch },
    remarkspayload: RemarksPayload
  ) {
    const rmkpayload: RemarksPayload = {
      model: remarkspayload.model,
      remarks: remarkspayload.remarks
    };
    context.commit(types.CHANGE_CART_REMARKS, rmkpayload);
    context.dispatch('saveToStorage');
  },
  changeCartQuantity(
    context: { commit: Commit; dispatch: Dispatch },
    quantitypayload: QuantityPayload
  ) {
    const qtypayload: QuantityPayload = {
      model: quantitypayload.model,
      quantity: quantitypayload.quantity
    };
    context.commit(types.CHANGE_CART_QUANTITY, qtypayload);
    context.dispatch('saveToStorage');
  },
  clearCart(context: { commit: Commit; dispatch: Dispatch }) {
    context.commit(types.CHECKOUT_REQUEST);
    context.dispatch('saveToStorage');
  },
  restoreCart(
    context: { commit: Commit; dispatch: Dispatch },
    cartpayload: CartShape[]
  ) {
    context.commit(types.RESTORE_CART, cartpayload);
  },
  saveToStorage(context: { commit: Commit; state: State }) {
    if (context.state.added.length > 0) {
      localStorage.setItem(
        'growcart',
        encodeURIComponent(JSON.stringify(context.state.added))
      );
    } else {
      localStorage.setItem('growcart', encodeURIComponent('[]'));
    }
  }
};

// mutations
const mutations = {
  [types.ADD_TO_CART](state: State, payload: AddToCartPayload) {
    state.checkoutStatus = null;
    const record = state.added.find((p) => p.model === payload.model);
    if (!record) {
      state.added.push({
        id: payload.id,
        checked: payload.checked,
        model: payload.model,
        color: payload.color,
        remarks: '',
        quantity: 1
      });
    } else {
      record.quantity++;
    }
  },
  [types.RESTORE_CART](state: State, payload: CartShape[]) {
    state.checkoutStatus = null;
    for (let i = 0; i < payload.length; i++) {
      state.added.push({
        id: payload[i].id,
        checked: payload[i].checked,
        model: payload[i].model,
        color: payload[i].color,
        remarks: payload[i].remarks,
        quantity: payload[i].quantity
      });
    }
  },
  [types.REMOVE_TO_CART](state: State, payload: modelPayload) {
    state.added.splice(
      state.added.findIndex((p) => p.model === payload.model),
      1
    );
  },
  [types.CHECKOUT_REQUEST](state: State) {
    state.added = [];
    state.checkoutStatus = null;
  },
  [types.CHECKOUT_RESET](state: State) {
    state.checkoutStatus = null;
  },
  [types.CHECKOUT_SUCCESS](state: State, payload: CheckoutSuccessPayload) {
    state.added = state.added.filter((p) => !payload.models.includes(p.model, 0));
    state.checkoutStatus = 'successful';
  },
  [types.CHECKOUT_FAILURE](state: State) {
    state.checkoutStatus = 'failed';
  },
  [types.CHANGE_CART_CHECKED](state: State, payload: CheckedPayload) {
    const record = state.added.find((p) => p.model === payload.model);
    if (record) {
      record.checked = payload.checked;
    }
  },
  [types.CHANGE_CART_REMARKS](state: State, payload: RemarksPayload) {
    const record = state.added.find((p) => p.model === payload.model);
    if (record) {
      record.remarks = payload.remarks;
    }
  },
  [types.CHANGE_CART_QUANTITY](state: State, payload: QuantityPayload) {
    const record = state.added.find((p) => p.model === payload.model);
    if (record) {
      record.quantity = payload.quantity;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
