import CryptoJS from 'crypto-js';

export namespace AESencrypt {
  export class AES {
    private Key: string;
    private Iv: string;
    constructor(key: string, iv?: string) {
      this.Key = key;
      this.Iv = !iv ? '1234567890898898' : iv;
    }
    Encrypt(str: string): string {
      const KEY = CryptoJS.enc.Utf8.parse(this.Key);
      const IV = CryptoJS.enc.Utf8.parse(this.Iv);
      const srcs = CryptoJS.enc.Utf8.parse(str);
      const encrypted = CryptoJS.AES.encrypt(srcs, KEY, {
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return encrypted.ciphertext.toString();
    }
    Decrypt(str: string): string {
      const KEY = CryptoJS.enc.Utf8.parse(this.Key);
      const IV = CryptoJS.enc.Utf8.parse(this.Iv);
      const encryptedHexStr = CryptoJS.enc.Hex.parse(str);
      const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
      const decrypt = CryptoJS.AES.decrypt(srcs, KEY, {
        iv: IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      const decryptedStr = decrypt.toString();
      return decryptedStr.toString();
    }
  }
}
