import { GetterTree, Getter } from 'vuex';
import { State, CartProduct } from './index';

const cartProducts: Getter<State, any> = (state: State) => {
  return state.cart.added.map((shape) => {
    const product = state.products.all.find((p) => p.id === shape.id);
    if (product) {
      const cartProduct: CartProduct = {
        id: shape.id,
        checked: shape.checked,
        img: product.img,
        title: product.title,
        href: product.href,
        model: shape.model,
        color: shape.color,
        remarks: shape.remarks,
        price: product.price,
        quantity: shape.quantity
      };
      return cartProduct;
    }
  });
};

const getterTree: GetterTree<State, any> = {
  cartProducts
};

export default getterTree;
