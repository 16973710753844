import Vue from 'vue';
import ElementUI from 'element-ui';

import '@/assets/css/site.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import 'font-awesome/css/font-awesome.min.css';
import axiosPlugin from './api';

import App from './App.vue';
import router from './router';
import store from './store';

import { currency } from './currency';
import i18n from './i18n/i18n';

Vue.directive('title', (el, binding) => {
  document.title = binding.value;
});
Vue.filter('currency', currency);

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  i18n: (
    key: string,
    value?:
      | any[]
      | {
          [key: string]: any;
        }
      | undefined
  ) => i18n.t(key, value)
});
Vue.use(axiosPlugin);

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.getters.token === '') {
      next({
        path: '/Login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
