import { Commit } from 'vuex';
import * as types from '../mutation-types';

export interface State {
  currency: string;
  token: string;
  aeskey: string;
  level: string;
  name: string;
  sessionkey: string;
  contentWidth: number;
  noprint: boolean;
  paramindex: string;
}

// initial state
// shape: [{ id, quantity }]
const state: State = {
  currency: '￥',
  token: '',
  aeskey: '',
  level: '',
  name: '',
  sessionkey: '',
  contentWidth: 0,
  noprint: true,
  paramindex: ''
};

// getters
const getters = {
  getCurrency: (state: State) => state.currency,
  token: (state: State) => state.token,
  aeskey: (state: State) => state.aeskey,
  level: (state: State) => state.level,
  firstname: (state: State) => state.name,
  sessionkey: (state: State) => state.sessionkey,
  contentWidth: (state: State) => state.contentWidth,
  noprint: (state: State) => state.noprint,
  paramindex: (state: State) => state.paramindex
};

// actions
const actions = {
  setCurrency(context: { commit: Commit; state: State }, currency: string) {
    context.commit(types.USER_SETCURRENCY, currency);
  },
  setToken(context: { commit: Commit; state: State }, token: string) {
    context.commit(types.USER_SETTOKEN, token);
  },
  setAeskey(context: { commit: Commit; state: State }, key: string) {
    context.commit(types.USER_SETAESKEY, key);
  },
  setLevel(context: { commit: Commit; state: State }, level: string) {
    context.commit(types.USER_SETLEVEL, level);
  },
  setFirstname(context: { commit: Commit; state: State }, name: string) {
    context.commit(types.USER_SETNAME, name);
  },
  setSessionkey(context: { commit: Commit; state: State }, sessionkey: string) {
    context.commit(types.USER_SETSESSION, sessionkey);
  },
  setContentWidth(context: { commit: Commit; state: State }, width: number) {
    context.commit(types.USER_CONTENTWIDTH, width);
  },
  setNoPrint(context: { commit: Commit; state: State }, isprint: boolean) {
    context.commit(types.USER_NOPRINT, isprint);
  },
  setParamIndex(context: { commit: Commit; state: State }, index: string) {
    context.commit(types.USER_PARAMINDEX, index);
  }
};

// mutations
const mutations = {
  [types.USER_SETCURRENCY](state: State, Currency: string) {
    state.currency = Currency;
  },
  [types.USER_SETTOKEN](state: State, Token: string) {
    state.token = Token;
  },
  [types.USER_SETAESKEY](state: State, Key: string) {
    state.aeskey = Key;
  },
  [types.USER_SETLEVEL](state: State, Level: string) {
    state.level = Level;
  },
  [types.USER_SETNAME](state: State, Name: string) {
    state.name = Name;
  },
  [types.USER_SETSESSION](state: State, Sessionkey: string) {
    state.sessionkey = Sessionkey;
  },
  [types.USER_CONTENTWIDTH](state: State, width: number) {
    state.contentWidth = width;
  },
  [types.USER_NOPRINT](state: State, isprint: boolean) {
    state.noprint = isprint;
  },
  [types.USER_PARAMINDEX](state: State, index: string) {
    state.paramindex = index;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
