// en.js
import enLocale from './elen';
const en = {
  message: {
    hello: 'Hello,',
    visitor: 'visitor',
    website: 'Welcome to gm-valves website.',
    company: 'SUZHOU GM VALVES CO.,LTD.',
    Copyright: 'All Rights Reserved',
    login: 'Login',
    register: 'Register',
    about: 'About',
    currency: 'Currency',
    placeholder: 'Please input',
    searchmail: 'Search email',
    searchfile: 'Search filename',
    amount: 'Amount',
    empty: 'Empty',
    home: 'Home',
    news: 'News',
    products: 'Products',
    supports: 'Supports',
    contactus: 'ContactUS',
    shoppingcart: 'Order',
    telephone: 'Telephone',
    my: 'My',
    hotline: 'Service Hotline',
    catalog: 'Products Catalogue',
    ballvalve: 'Ball Valve',
    notfound: 'Not Found The Page!',
    networkerror: 'Network error. Please check if the network is connected!',
    initinfo: 'Initialization takes some minutes,Thank you for waiting!',
    errorsubmit: 'error submit!!',
    address: 'Z1613 Guotai Xintiandi Square, No.19 Renmin East Road, Yangshe Town, Zhangjiagang City, Suzhou, Jiangsu, China.'
  },
  ...enLocale
};

export default en;
