export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const RESTORE_CART = 'RESTORE_CART';
export const CHANGE_CART_CHECKED = 'CHANGE_CART_CHECKED';
export const CHANGE_CART_REMARKS = 'CHANGE_CART_REMARKS';
export const CHANGE_CART_QUANTITY = 'CHANGE_CART_QUANTITY';
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const USER_SETCURRENCY = 'USER_SETCURRENCY';
export const USER_SETTOKEN = 'USER_SETTOKEN';
export const USER_SETAESKEY = 'USER_SETAESKEY';
export const USER_SETLEVEL = 'USER_SETLEVEL';
export const USER_SETNAME = 'USER_SETNAME';
export const USER_SETSESSION = 'USER_SETSESSION';
export const USER_CONTENTWIDTH = 'USER_CONTENTWIDTH';
export const USER_NOPRINT = 'USER_NOPRINT';
export const USER_PARAMINDEX = 'USER_PARAMINDEX';
