// cn.js
import zhLocale from './elcn';

const cn = {
  message: {
    hello: '您好,',
    visitor: '游客',
    website: '欢迎访问盖美阀门网站',
    company: '苏州盖美阀门有限公司',
    Copyright: '版权所有',
    login: '登录',
    register: '注册',
    about: '关于',
    currency: '货币',
    placeholder: '请输入内容',
    searchmail: '搜索邮件',
    searchfile: '搜索文件',
    amount: '金额',
    empty: '清空',
    home: '主页',
    news: '公司新闻',
    products: '产品中心',
    supports: '支持与服务',
    contactus: '联系我们',
    shoppingcart: '购物车',
    telephone: '电话',
    my: '我的',
    hotline: '服务热线',
    catalog: '产品图册',
    ballvalve: '球阀',
    notfound: '该页面不存在',
    networkerror: '网络错误, 请检查网络是否连接!',
    initinfo: '初始化需要一定时间，请耐心等待返回结果!',
    errorsubmit: '提交错误!!',
    address: '江苏省苏州市张家港市杨舍镇人民东路19号(国泰新天地广场)Z1613'
  },
  ...zhLocale
};

export default cn;
