import { Commit, Dispatch, Action, ActionTree } from 'vuex';
import * as types from './mutation-types';
import { State, Product, AddToCartPayload } from './index';

const addToCart: Action<State, any> = (
  context: { commit: Commit; dispatch: Dispatch },
  product: Product
) => {
  if (product.inventory > 0) {
    const payload: AddToCartPayload = {
      id: product.id,
      checked: false,
      model: product.model,
      color: product.color
    };
    context.commit(types.ADD_TO_CART, payload);
    context.dispatch('saveToStorage');
  }
};

const actions: ActionTree<State, any> = {
  addToCart
};

export default actions;
