// i18n.js

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './langs';
Vue.use(VueI18n);
const lang = navigator.language.toLowerCase().indexOf('zh') === 0 ? 'cn' : 'en';
// 从localStorage中拿到用户的语言选择，如果没有，那默认中文。
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || lang,
  messages
});
export default i18n;
