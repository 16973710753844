import Vue from 'vue';
import Vuex, { Commit, Dispatch } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import actions from './actions';
import getters from './getters';
import cart, { State as CardState } from './modules/cart';
import products, { State as ProductsState } from './modules/products';
import user, { State as UserState } from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    cart,
    products,
    user
  },
  plugins: [createPersistedState({
      storage: window.sessionStorage
    })]
});

export interface ActionContextBasic {
  commit: Commit;
  dispatch: Dispatch;
}

export interface State {
  cart: CardState;
  products: ProductsState;
  user: UserState;
}

export type CheckoutStatus = 'successful' | 'failed' | null;

export interface Product {
  id: number;
  name: string;
  img: any;
  title: string;
  href: string;
  model: string;
  color: string;
  price: number;
  inventory: number;
}

export interface CartShape {
  id: number;
  checked: boolean;
  model: string;
  color: string;
  remarks: string;
  quantity: number;
}

export interface CartProduct {
  id: number;
  checked: boolean;
  img: any;
  title: string;
  href: string;
  model: string;
  color: string;
  remarks: string;
  price: number;
  quantity: number;
}

export interface AddToCartPayload {
  id: number;
  checked: boolean;
  model: string;
  color: string;
}

export interface CheckedPayload {
  model: string;
  checked: boolean;
}

export interface RemarksPayload {
  model: string;
  remarks: string;
}

export interface QuantityPayload {
  model: string;
  quantity: number;
}
