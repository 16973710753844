import Vue, { AsyncComponent } from 'vue';
import Router, { RouteConfig, Route, NavigationGuard } from 'vue-router';

const Home: AsyncComponent = (): any => import('@/views/Home.vue');
const Index: AsyncComponent = (): any => import('@/views/Home/Index.vue');
const News: AsyncComponent = (): any => import('@/views/Home/News.vue');
const NewsDetail: AsyncComponent = (): any =>
  import('@/views/Home/NewsDetail.vue');
const About: AsyncComponent = (): any => import('@/views/Home/About.vue');
const ContactUs: AsyncComponent = (): any =>
  import('@/views/Home/ContactUs.vue');
const Login: AsyncComponent = (): any => import('@/views/Home/Login.vue');
const Products: AsyncComponent = (): any => import('@/views/Home/Products.vue');
const ProductDetail: AsyncComponent = (): any =>
  import('@/views/Home/ProductDetail.vue');
const Register: AsyncComponent = (): any => import('@/views/Home/Register.vue');
const Forgot: AsyncComponent = (): any => import('@/views/Home/Forgot.vue');
const Supports: AsyncComponent = (): any => import('@/views/Home/Supports.vue');
const Checkout: AsyncComponent = (): any => import('@/views/Home/Checkout.vue');
const Verify: AsyncComponent = (): any => import('@/views/Verify.vue');
const MyHome: AsyncComponent = (): any => import('@/views/MyHome.vue');
const Dashboard: AsyncComponent = (): any =>
  import('@/views/Myhome/Dashboard.vue');
const Initsystem: AsyncComponent = (): any =>
  import('@/views/Myhome/Initsystem.vue');
const NotFound: AsyncComponent = (): any => import('@/views/404.vue');
const Changepwd: AsyncComponent = (): any =>
  import('@/views/Myhome/Changepwd.vue');
const Userdetail: AsyncComponent = (): any =>
  import('@/views/Myhome/Userdetail.vue');
const SetMail: AsyncComponent = (): any => import('@/views/Myhome/SetMail.vue');
const SetDatabase: AsyncComponent = (): any =>
  import('@/views/Myhome/SetDatabase.vue');
const MenuRights: AsyncComponent = (): any =>
  import('@/views/Myhome/MenuRights.vue');
const FilesCenter: AsyncComponent = (): any =>
  import('@/views/Myhome/FilesCenter.vue');
const NewMail: AsyncComponent = (): any => import('@/views/Myhome/NewMail.vue');
const MailBox: AsyncComponent = (): any => import('@/views/Myhome/MailBox.vue');
const Readmail: AsyncComponent = (): any => import('@/views/Myhome/Readmail.vue');
const Refresh: AsyncComponent = (): any => import('@/views/Myhome/Refresh.vue');

Vue.use(Router);

const routes: RouteConfig[] = [
  {
    path: '/',
  name: 'home',
    component: Home,
    children: [
      {
        path: '/',
        name: 'index',
        component: Index
      },
    {
        path: '/Index',
        name: 'Index',
        component: Index
      },
      // {
      //   path: '/News',
      //   name: 'News',
      //   component: News
      // },
      // {
      //   path: '/NewsDetail',
      //   name: 'NewsDetail',
      //   component: NewsDetail
      // },
      {
        path: '/Products',
        name: 'Products',
        component: Products
      },
      {
        path: '/ProductDetail',
        name: 'ProductDetail',
        component: ProductDetail
      },
      {
        path: '/Supports',
        name: 'Supports',
        component: Supports
      },
      {
        path: '/ContactUs',
        name: 'ContactUs',
        component: ContactUs
      },
      {
        path: '/Login',
        name: 'Login',
        component: Login
      },
      {
        path: '/Register',
        name: 'Register',
        component: Register
      },
      {
        path: '/Forgot',
        name: 'Forgot',
        component: Forgot
      },
      {
        path: '/About',
        name: 'About',
        component: About
      },
      {
        path: '/Checkout',
        name: 'Checkout',
        component: Checkout
      },
    ]
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify
  },
  {
    path: '/MyHome',
    component: MyHome,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { requireAuth: true },
        component: Dashboard
      },
      {
        path: '/initsystem',
        name: 'Initsystem',
        meta: { requireAuth: true },
        component: Initsystem
      },
      {
        path: '/changepwd',
        name: 'Changepwd',
        meta: { requireAuth: true },
        component: Changepwd
      },
      {
        path: '/userdetail',
        name: 'Userdetail',
        meta: { requireAuth: true },
        component: Userdetail
      },
      {
        path: '/setdatabase',
        name: 'SetDatabase',
        meta: { requireAuth: true },
        component: SetDatabase
      },
      {
        path: '/setmail',
        name: 'SetMail',
        meta: { requireAuth: true },
        component: SetMail
      },
      {
        path: '/menurights',
        name: 'MenuRights',
        meta: { requireAuth: true },
        component: MenuRights
      },
      {
        path: '/filescenter',
        name: 'FilesCenter',
        meta: { requireAuth: true },
        component: FilesCenter
      },
      {
        path: '/newmail',
        name: 'NewMail',
        meta: { requireAuth: true },
        component: NewMail
      },
      {
        path: '/mailbox',
        name: 'MailBox',
        meta: { requireAuth: true },
        component: MailBox
      },
      {
        path: '/readmail',
        name: 'Readmail',
        meta: { requireAuth: true },
        component: Readmail
      },
      {
        path: '/refresh',
        name: 'Refresh',
        component: Refresh
      },
    ]
  },
  {
    path: '*',
    component: NotFound
  },
];
const router: Router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
