const digitsRE = /(\d{3})(?=\d)/g;

export function currency(value: any, currency: any, decimals: any) {
  const amount = parseFloat(value);
  if (!isFinite(amount) || (!amount && amount !== 0)) { return value; }
  currency = currency !== null ? currency : '$';
  decimals = decimals !== null ? decimals : 2;
  const stringified = Math.abs(amount).toFixed(decimals);
  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = _int.length % 3;
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? ',' : '') : '';
  const _float = decimals ? stringified.slice(-1 - decimals) : '';
  const sign = amount < 0 ? '-' : '';
  return (
    sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float
  );
}
